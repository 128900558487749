(() => {
  stimulus.register("lightbox", class extends Stimulus.Controller {
    static targets = ["modal", "background", "frame"];

    handleOpen(event) {
      event.preventDefault();
      this.modalTarget.classList.remove("hidden");
      this.backgroundTarget.classList.remove("opacity-0");
      this.backgroundTarget.classList.add("opacity-100");

      const { key } = event.params;
      this.dispatch("open", { detail: { key } });
    }

    handleClose(event) {
      event.preventDefault();
      this.modalTarget.classList.add("hidden");
      this.backgroundTarget.classList.remove("opacity-100");
      this.backgroundTarget.classList.add("opacity-0");
      this.dispatch("close");
    }
  });
})();
